<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form   @submit.prevent="fetch(1)">
              <b-row>
                <b-col>
                  <b-form-input class="mr-1" v-model="filter.q" placeholder="Recherche par Marque, Modèle, Immat, Client..." />
                </b-col>
                <b-col>
                    <div class="d-flex align-items-center">
                      <label>Date de création du</label>
                   
                      <cleave
                          id="date_mec"
                          v-model="filter.date_debut"
                          class="form-control"
                      
                          :raw="false"
                          :options="{
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      }"
                          placeholder="JJ/MM/AAAA"
                      />
                    </div>
                    
               
                </b-col>
                <b-col>

                  <div class="d-flex align-items-center">
                    <label class="mr-2">Au </label>
                    <cleave
                        id="date_mec"
                        v-model="filter.date_fin"
                        class="form-control"

                        :raw="false"
                        :options="{
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      }"
                        placeholder="JJ/MM/AAAA"
                    />
                  </div>               
                </b-col>
                
                

<!--                <b-col v-if="$can('voir-reprise-concession', 'reprise') || $can('voir-reprise-global', 'reprise')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>-->
<!--                </b-col>-->
<!--                <b-col v-if="$can('voir-reprise-concession', 'reprise') || $can('voir-reprise-site', 'reprise') || $can('voir-reprise-global', 'reprise')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>-->
<!--                </b-col>-->
                <b-col md="auto">
                  <b-button type="submit"  variant="primary">Recherche</b-button>
                  <b-button v-if="$can('exporter-reprise', 'vehicule')" :disabled="pendindExport"  @click="exportHandler" class="ml-1"  variant="primary">
                    <b-spinner small v-if="pendindExport" ></b-spinner>  
                    Exporter
                  </b-button>
                </b-col>
              </b-row>
   
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="is_loading">
          <b-card

              no-body
          >
            <div class="m-2 d-flex justify-content-between">

              <!-- Table Top -->
              <b-button
                  v-if="$can('creer-modifier-reprise', 'reprise')"
                  variant="primary"
                  :to="{ name: 'creer-reprise'}"
              >
                Créer reprise
              </b-button>
              <div class="align-self-center">
                {{pagination.total}} éléments
              </div>

            </div>
            <!-- table -->
            <b-table v-if="items"
                     ref="selectableTable"

                     :items="items"
                     :fields="fields"
                     responsive
                     class="mb-0"
            >
              <template #cell(vehicule)="data">

                <vehicule-overview :vehicule="data.item.vehicule" />
              </template>
              <template #cell(vendeur)="data">
                <div>
                  <span>Vendeur</span> : <span class="font-weight-bold">{{ data.item.vendeur.full_name }}</span>
                </div>
                <div>
                  <span>Client</span> : <span class="font-weight-bold">{{ data.item.proprietaire.full_name }}</span>
                </div>

              </template>
              <template #cell(statut)="data">
                  
                  <b-badge class="mb-1" variant="success">{{data.item.statut_vente.label}}</b-badge> <br/>
                  <b-badge :variant="data.item.statut_expertise_manuelle.variant">{{data.item.statut_expertise_manuelle.libelle}}</b-badge> <br/>
                  <b-badge :variant="data.item.statut_cotation.variant">{{data.item.statut_cotation.libelle}}</b-badge> 
              </template>
              <template #cell(id)="data">
                  <router-link :to="{name : 'reprise-view', params : {id : data.item.id}}">{{data.item.id}}</router-link>
              </template>
              <template #cell(actions)="data">
                <feather-icon

                    icon="EyeIcon"
                    size="16"
                    class="mx-1 cursor-pointer  "
                    @click="$router.push({name : 'reprise-view', params : {id : data.item.id}})"
                />
                <feather-icon
                    v-if="$can('creer-modifier-reprise', 'reprise')"
                    icon="EditIcon"
                    size="16"
                    class="mx-1 cursor-pointer  "
                    @click="$router.push({name : 'editer-vehicule', params : {id : data.item.id}})"
                />
                 
              </template>
            </b-table>


          </b-card>
        </b-overlay>

      </b-col>
    </b-row>
    <b-row v-if="items.length == 0">
      <b-col>
        <b-card class="text-center">
          Aucun véhicule trouvé dans reprise
        </b-card>
      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import axios from "@/libs/axios";


export default {
  components: {
    VehiculeOverview,
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pendindExport : false,
      fields: [
        {
          'key': 'id',
        },
        {
          'key': 'vehicule',
          'label': 'Véhicule'
        },
        {
          'key': 'vendeur',
          'label': ''
        },
        {
          'key': 'statut',
          'label': 'Statut'
        },
        {
          'key': 'actions',
          'label': 'Action'
        },
      ],
      items: [],
      filter : {
        q : ""
      },
      page : 1,
      pagination : {},
      is_loading : false
    }
  },
  created() {
      this.fetch(1);
  },
  methods: {
    exportHandler(){
      let _this = this;
      _this.pendindExport = true;
      
      axios.get(
          process.env.VUE_APP_API_URL + 'api/vehicules/reprise?export=1&q=' + _this.filter.q  + '&date_debut=' + _this.filter.date_debut + '&date_fin=' + _this.filter.date_fin,
          {responseType: 'blob'} // !!!
      ).then((response) => {
        _this.pendindExport = false;
        window.open(URL.createObjectURL(response.data));
      });
    },
    fetch(page){
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('repriseModule/fetchReprises', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
        _this.is_loading = false;
      });
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
